const Endpoints = {
  //Auth
  login: '/login',
  forgotPassword: '/user/admin/forgotPassword',
  verifyOTP: '/user/admin/verifyOTP',
  resetPassword: '/user/admin/resetPassword',

  loginDashboard: "login",
  forgotPass: "/open/forgotPassword",
  verifyOtp: "/open/verifyOTP",
  // resendOtp: "/open/changePassword",
  changePass: "/open/changePassword",


  //Dashboard
  commonDashboard: '/dashboard/admin/commonDashboard',
  boardOnboardAnalytics: '/dashboard/admin/boardOnboardAnalytics',
  studentTeacherAnalytics: '/dashboard/admin/studentTeacherAnalytics',
  getAllTelegramGroup: "/automations/getAllTelegramGroup",

  //User Module
  userLists: '/user/admin/subAdminList',
  updateSubAdminStatus: '/user/admin/updateSubAdminStatus',
  createSubAdmin: '/user/admin/createSubAdmin',
  updateSubAdmin: '/user/admin/updateSubAdmin',


  //projects
  viewProjects: '/employee/viewProjects',


  //School Module
  schoolList: '/school/admin/schoolList',
  updateSchoolStatus: '/school/admin/updateSchoolStatus',
  schoolBoards: '/school/admin/schoolBoards',
  createSchool: '/school/admin/createSchool',
  updateSchool: '/school/admin/updateSchool',

  //Admin Profile Module
  getProfileDetails: '/user/admin/getProfileDetails',
  changePassword: '/user/admin/changePassword',


  //myprofile end point
  viewProfile: '/employee/viewProfile',
  updateProfile: '/employee/updateProfile',
  //User Module
  userLists: '/user/admin/subAdminList',
  updateSubAdminStatus: '/user/admin/updateSubAdminStatus',
  createSubAdmin: '/user/admin/createSubAdmin',
  updateSubAdmin: '/user/admin/updateSubAdmin',

  //Upload API
  upload: '/file/upload',

  //compoff
  addCompOff: '/employee/addCompOff',
  compOffList: '/employee/compOff',
  viewCompOff: '/employee/viewCompOff?id=',
  mappedProject: '/employee/mappedProject',
  pmCompOff: '/projectManager/pmCompOffApproval',
  pmViewCompOff: '/projectManager/viewCompOff',

  //manageteam
  addTeam: "/admin/createTeam",
  editTeam: "/admin/editTeam",
  listTeam: "/admin/listTeam",
  deleteTeam: "/admin/deleteTeam",

  //taskcompletion view
  taskCompletionView: '/employee/taskCompletionView',
  employeelist: '/employee/employeelist',
  mappedProject: '/employee/mappedProject',

  //otherEod
  otherEOD: '/employee/otherEOD',
  eodTypes: '/employee/eodTypes',
  //EodReport
  eodReports: '/employee/eodReports',
  //Holidays
  getHolidayList: '/employee/getHolidayList',
  addHoliday: '/admin/addHoliday',
  deleteHoliday: '/admin/deleteHoliday',
  updateHoliday: '/admin/updateHoliday',
  //referyourfriend
  referAFriend: '/employee/referAFriend',

  // leaveTypes: '/employee/leaveTypes',
  // holidayList: '/employee/getHolidayList',
  // applyLeave: '/employee/applyLeave',
  // leaveList: '/employee/listAppliedLeaves',
  // viewLeave: '/employee/viewLeave?leaveId=',
  // getLeaves: '/employee/getLeaves',
  // monthlyLeave: '/employee/monthlyLeaveReport',

  //leavemanagement
  leaveTypes: '/employee/leaveTypes',
  holidayList: '/employee/getHolidayList',
  applyLeave: '/employee/applyLeave',
  leaveList: '/employee/listAppliedLeaves',
  viewLeave: '/employee/viewLeave?leaveId=',
  getLeaves: '/employee/getLeaves',
  monthlyLeave: '/employee/monthlyLeaveReport',
  withDrawLeave: "/employee/withdrawLeave",
  withDrawCompOff: "/employee/withdrawCompOff",
  monthYearDate: "/employee/monthYearData",
  applyLeaveProject: "/employee/mappedProjectLeave",
  compOffLeaveProject: "/employee/mappedProjectCompOff",

  // Referral Approval
  referralApprovals: "/admin/referralApprovals",
  updateApprovals: "/admin/updateApprovals",
  updateReferral: "admin/referralapproval",

  //Manage employee
  employeelist: "/admin/employeelist",
  addemployee: "/admin/addemployee",
  updateemployee: "/admin/updateemployee",
  deleteEmployee: "/admin/deleteemployee",
  getTeamList: "/admin/listTeam",
  getAllPosition: "/admin/employeePosition",
  screenList: "/admin/frontendScreens",

  //leavebalance
  leaveBalance: "/admin/getEmployeeLeaves",
  //Admin leave approval
  leaveApproval: "/admin/leaveApprovalList",
  leaveApprovalView: "/admin/updateLeave",
  multipleLeaveApprove: "/admin/multipleLeaveApprove",

  //project profile
  viewProjects: "/employee/viewProjects",
  projectEOD: "/employee/projectEOD/",
  projectDetails: "/employee/projectDetails/",
  mangeEod: "/employee/manageEOD",
  mageEodGet: "/employee/manageEOD/",



  //ADMIN PROJECT
  viewProject: "/admin/viewProject",
  addProject: "/admin/addProject",
  editProject: "/admin/editProject",
  archieveProject: "/admin/archieveProject",
  deleteProject: "/admin/deleteProject",
  teamBudget: "/admin/teamBudget",
  getProject: '/admin/getProject',
  //archived projects
  unArchivedProjectList: '/admin/unArchivedProjectList',
  unArchivedProject: "/admin/unArchivedProject",


  // ANNOUNCEMENT
  announcementList: "/admin/announcementlist",
  updateAnnouncement: "/admin/announcementupdate",
  addAnnouncement: "/admin/announcementadd",
  deleteAnnouncement: "/admin/deleteannouncement",
  viewAnnouncement: "/admin/getannouncement",
  telegramList: "/admin/listtelegramgroup",
  bannerList: "/admin/bannerFields",
  bannerLaunch: "/admin/dashboard/bannerLanch",
  //PMALLOCATIONS:
  approvalProject: '/projectManager/approvalProject',
  approvalSprint: '/projectManager/approvalSprint',
  //allocation endpoint
  allocationList: "/admin/allocationList",
  projectMangerList: "/admin/pmList",
  resourceAllocationDetails: "/admin/resourceAllocationDetails?id=",
  teamBudgetAllocation: "/admin/teamBudgetAllocation?id=",
  resourceList: "/admin/resourceList?teamId=",
  resourceListPost: "/admin/resourceAllocation?id=",

  //PM PROJECT

  projectData: '/projectManager/projectList',
  listSprint: '/projectManager/listSprint',
  duplicateSprint: '/projectManager/duplicateSprint?sprintId=',
  deleteSprint: '/projectManager/deleteSprint',
  addSprint: '/projectManager/addSprint',
  updateSprint: '/projectManager/updateSprint',
  sprintDetails: '/projectManager/sprintDetails',
  projectResource: '/projectManager/projectResource',
  mappedTeam: '/projectManager/mappedTeam',
  SprintTimeLine: '/projectManager/SprintTimeLine',
  // DEVOPS Daily Eod
  devopslisteod: "/devops/listeod",
  devopsaddeod: "/devops/addeod",
  weeklyHours: "/devops/weeklyHours",
  taskApprove: "/devops/taskApprove",
  projectList: "/qualityassurance/projectList",
  // QA DAILY EOD
  listeod: "/qualityassurance/listeod",
  addEod: "/qualityassurance/addeod",
  listtestingtype: "/qualityassurance/listtestingtype",
  projectList: "/qualityassurance/projectList",


  // TEAM QA EOD
  listqahead: "/qualityassurance/listqahead",
  approveTask: "/qualityassurance/qaheadtaskapprove",
  qamultiApprove: "/qualityassurance/qaheadtaskmultipleapprove",
  // TELEGRAM GROUP
  telegramList: "/admin/listtelegramgroup",
  addTelegram: "/admin/addtelegramgroup",
  deleteTelegram: "/admin/deletetelegramgroup",
  //Resource or User dashboard
  pieChart: "/employee/dashboard/pieChart",
  lineChart: "/employee/dashboard/lineChart",
  todaysAllocation: "/employee/dashboard/todaysAllocation",
  onGoingProject: "/employee/dashboard/onGoingProject",
  weeklyData: '/employee/dashboard/currentWeekContribution',
  currentMonthData: '/employee/dashboard/currentMonthContribution',
  lastMonthData: '/employee/dashboard/lastMonthContribution',



  //Notifications API
  getNotifications: '/employee/employeenotification',
  clearNotifications: '/employee/employeenotificationclear',

  //ADMIN Devops EOD Report
  getAllDevopsReport: '/admin/devopsEodReport',
  //ADMIN QA Daily EOD Report
  getALLQAReport: '/admin/qaEodReport',

  //TECH ARCH Daily EOD Report
  getAllTechArchReport: "/admin/techEodReport",
  //PM Daily EOD Report
  getAllPMReport: "/admin/pmEodReport",

  // PM LEAVE APPROVAL
  getAllPmLeaveList: "/projectManager/viewLeave",
  pmApprovalLeave: "/projectManager/pmLeaveApproval",
  // ADMIN DASHBOARD
  adminDashboard: "/admin/Dashboard/employeeCount",
  adminExitEmployeeList: "/admin/dashboard/exitEmployee",
  adminNewJoinEmployeeList: "/admin/dashboard/newJoinee",
  // PM DASHBOARD
  pmDashboard: "/projectManager/dashboard/projectList",


  //PM Allocation endpoint
  PmAllocationDetails: "/projectManager/allocateProject?",
  PmAllocationResourceDetails: "/projectManager/allocateResource",
  PmAllocationResourceFilter: "/projectManager/allocateResource?page=1&limit=10&search=&filter=",

  // Header
  updateWorkStatus: "/employee/updateWorkFromStatus",
  closeSprint: "/projectManager/closeSprint",
  approvalResource: "/projectManager/approvalResource",
  bulkApprovalTask: "/projectManager/bulkApprovalTask",
  viewResourceEOD: "/projectManager/viewResourceEOD",
  approvalTaskResource: "/projectManager/approvalTaskResource",

  //Burn Report
  getAllprojects: '/admin/getAllprojects',
  exportBurntReport: '/admin/exportBurntReport',
  // Admin Other EOD
  adminApprovalOtherEod: "/admin/approvalotherEOD",
  otherEodMultiApprove: "/admin/approvalotherEODs",
  bulKRejectEOD: '/admin/rejectOtherEODs',
  rejectEODByID: '/admin/rejectOtherEODById',
  approvalById: '/admin/aproveOtherEODById',
  // Exit Employee
  exitEmployeeList: "/admin/exitEmployeeList",
  viewExitEmployee: "/admin/viewExitEmployee",
  unarchieveEmployee: "/admin/unarchieveEmployee",
  // Allocation Request Admin
  allocationRequestList: "/admin/allocationRequestList",
  getAllPmList: "/admin/pmList",
  //Allocation Request PM
  pmAllocationRequest: "/projectManager/allocationRequestList",
  addAllocationNewRequest: "/projectManager/newAllocationRequest",
  allocationDropdown: "/projectManager/projectTeamResourceList",
  alertMessage: "/projectManager/checkAllocationRequest",
  // Tech Arch API
  getTechArchList: "/techArch/listeod",
  techArchProjectList: "/techArch/projectList",
  techArchAddEOD: "/techArch/addeod",
  techArchHeadList: "/techArch/listtahead",
  techArchApprove: "/techArch/qaheadtaskmultipleapprove",

  cuProjectList: "/projectManager/cuProjectList",
  cuResourceList: "/projectManager/cuResourceList",
  mappedCUResource: "/projectManager/mappedCUResource",
  manageCUAllocation: "/projectManager/manageCUAllocation",


  cuAdminProjectList: "/admin/cuProjectList",
  cuAdminResourceList: "/admin/cuResourceList",


  cuEmplooyeeProjectList: "/employee/cuProjectList",
  cuEodList: "/employee/cuEodList",
  escalation: "/employee/escalation",
  // PM Task Update
  getAllPmTask: "/projectManager/projectManagerEodList",
  addPmTask: "/projectManager/AddPMTodTask",
  pmProjectList: "/projectManager/pmprojects",

  //CU Allocation
  getAllCuAllocationList: "/admin/allocationCU",
  addCuAllocation: "/admin/resourceCUAllocation",
  teamResource: "/admin/teamResource",

  //Admin CompOff Approval
  adminCompOff: "/admin/compOffApprovalList",
  updateCompOff: "/admin/updatecompOff",
  multiCompOffApprove: "/admin/multiplecompOffApprove",


  //ADMIN EOD's Approval for Tech Arch, PM, Devops and QA

  actionsByIdForEod: "/admin/aproveTAPMDevopsById",
  actionsByMultiSelect: "/admin/approvalTAPMDevopsEODs",
  approveByIdQA: "/admin/qataskapprove",
  multiSelectApproveQA: "/admin/qataskmultipleapprove"

}






const APIMethods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
}

export default {
  Endpoints,
  APIMethods,
}
