import React, { useEffect, forwardRef, useState, useRef } from "react";
import { AppBar, Toolbar, Typography, makeStyles } from "@material-ui/core";
import "component/common/Header/header.scss";
import { request } from "service";
import endponts from "service/endponts";
import wfhRingIcon from "../../../assets/icons/header/wfh-ring.svg";
import wfoRingIcon from "../../../assets/icons/header/wfo-ring.svg";
import lockIcon from "../../../assets/icons/header/lock.svg";
import logInIcon from "../../../assets/icons/header/log-in.svg";
import profile from "../../../assets/images/profile.jpg";
import NotificationDrawer from "../Notification";
import { history } from "helpers";
const Header = () => {
  const [toggleWork, setToggleWork] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [data, setData] = useState("");


  const handleToggleWork = () => {
    request({
      url: `${endponts.Endpoints.updateWorkStatus}`,
      method: endponts.APIMethods.PUT,
      params: { status: toggleWork ? 1 : 2 },

    }).then(() => {
      let obj = {
        ...data,
        workFrom: toggleWork ? 1 : 2
      }
      localStorage.setItem("userData", JSON.stringify(obj))
    })
    setToggleWork(!toggleWork);
    setShowProfile(true)
    // window.location.reload()
  };


  const navigateToLogin = () => {
    history.push('/auth/login')
  }

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("userData"));
    setData(value);
    setToggleWork(value?.workFrom == 2 ? true : false);
  }, []);



  const displayDesktop = () => {
    return (
      <Toolbar
        onMouseOut={() => setShowProfile(false)}>
        <div className="container-fluid py-3">
          <div className="row ">
            <div className="col-md-4 d-flex align-items-center"></div>
            <div className="col-md-8 d-flex justify-content-end">
              <div className="d-flex justify-content-between align-items-center">
                <div className=" heasder-item d-flex align-items-center justify-content-center">
                  <a
                    href="https://sites.google.com/doodleblue.com/doodlewiki/home?authuser=1"
                    target="_blank"
                    rel="noreferrer"
                    className="font-regular-18 web me-4">
                    <label className="heartCss">doodlewiki</label></a>
                  {/* <NotificationDrawer /> */}
                  <div
                    className="mx-1"
                    style={{
                      display: "block",
                    }}
                  >
                    <div
                      id="list1"
                      className={`dropdown-check-list ${showProfile ? "visible" : ""
                        }`}
                      tabIndex="100"
                    >
                      <span className="anchor" onMouseOver={() => setShowProfile(true)}>
                        <img
                          className="user-icon"
                          width="25px"
                          height="25px"
                          src={(data.profilePicture != undefined && data?.profilePicture?.includes('http')) ? data?.profilePicture : profile}
                        />
                        <img
                          className="ring-photo"
                          width="25px"
                          height="25px"
                          src={toggleWork ? wfoRingIcon : wfhRingIcon}
                        />
                      </span>

                      <div onMouseOut={(e) => setShowProfile(false)} onMouseOver={() => setShowProfile(true)}>
                        <ul id="dropdownItems" className="items">
                          <li className="main-container p-3">
                            <div className="profile-container text-center">
                              <span className="mx-3 d-flex align-items-center justify-content-center">
                                <img
                                  className="photo"
                                  width="100px"
                                  height="100px"
                                  alt="ring"
                                  src={(data.profilePicture != undefined && data?.profilePicture?.includes('http')) ? data?.profilePicture : profile}
                                />
                                <img
                                  className="ring"
                                  width="100px"
                                  height="100px"
                                  alt="ring"
                                  src={toggleWork ? wfoRingIcon : wfhRingIcon}
                                />
                              </span>
                              <div className="profile-name font-regular-24">
                                {data.firstName + " " + data.lastName}
                              </div>
                              <p className="profile-mail font-regular-14">
                                {data.email}
                              </p>
                              <p className="profile-position font-regular-14">{data?.position}</p>
                              <div className="d-flex align-items-center fs-12">
                                <span className="font-regular-16 fw-600">Work From Office</span>
                                <input
                                  className="toggle-checkbox"
                                  name="toggleWork"
                                  id="toggleWork"
                                  type="checkbox"
                                  checked={!toggleWork}
                                  onChange={handleToggleWork}
                                />
                                <span className="font-regular-16 fw-600">Work From Home</span>
                              </div>
                            </div>
                          </li>
                          <hr className="my-1" />
                          <li className="px-3 py-2 cursor-pointer"
                            onClick={() => history.push({
                              pathname: "/auth/change-password",
                              state: { email: data.email }
                            })}>
                            <img className="me-2" src={lockIcon} />
                            Change Password
                          </li>
                          <li className="px-3 py-2 cursor-pointer"
                            onClick={() => navigateToLogin()}>
                            <img className="me-2" src={logInIcon} />
                            Logout
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Toolbar >
    );
  };

  return (
    <div className="header">
      <header>
        <AppBar style={{ boxShadow: "none", backgroundColor: "#fff" }}>
          {displayDesktop()}
        </AppBar>
      </header>
    </div>
  );
};

export default Header;
