import React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import "./style.scss";
import { NavLink } from "react-router-dom";


const Accordion = styled((props) => <MuiAccordion square {...props} />)(() => ({
    borderRadius: "8px",
    padding: 2,
    boxShadow: "none",
    // Border: "none",
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreRoundedIcon sx={{ fontSize: "10" }} />}
        {...props}
    />
))(() => ({
    backgroundColor: "#FFFFFF",

    "& .MuiAccordionSummary-content": {
        marginLeft: 1,
        padding: 2,
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
}));

export default function CustomAccordion({ onClick, index, parentIndex, activeIndex, label, icon, onClickCarausel, childIndex, isOpen, data, children, drawer }) {

    return (
        <div>
            <div>
                <Accordion>
                    {
                        !isOpen ?
                            (<div onClick={() => onClickCarausel()} className="icon openIcon">
                                <img src={icon} alt="icon" />
                            </div>)
                            :
                            (<div>

                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className="icon">
                                        <img src={icon} alt="icon" /></div>
                                    <label className="ms-1 labelStyle sidebar-label">{label}</label>
                                </AccordionSummary>
                            </div>)}
                    {isOpen && <AccordionDetails className="content-colour">
                        {Array.isArray(data) ? (<div>
                            {
                                data.map((item, i) => {
                                    return (
                                        <NavLink
                                            key={i}
                                            to={item.to}
                                            onClick={item.to !== "/something" ? () => onClick(i) : ""}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <div
                                                className={
                                                    (`${parentIndex}_${i}` === `${activeIndex}_${childIndex}`) ? "sideBarStyle" : "styleSidebar"
                                                }
                                                onClick={() => {
                                                    onClick(i);
                                                }}>
                                                <label className="cursor-pointer sidebar-label">{item.label}</label>
                                            </div>
                                        </NavLink>
                                    )
                                })
                            }
                        </div>) : (children)}

                    </AccordionDetails>}
                    {/* <AccordionDetails className="content-colour">
                        {Array.isArray(data) ? (<div>
                            {
                                data.map((item, i) => {
                                    return (
                                        <NavLink
                                            key={i}
                                            to={item.to}
                                            onClick={item.to !== "/something" ? () => onClick(i) : ""}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <div
                                                className={
                                                    (`${parentIndex}_${i}` === `${activeIndex}_${childIndex}`) ? "sideBarStyle" : "styleSidebar"
                                                }
                                                onClick={() => {
                                                    onClick(i);
                                                }}>
                                                <label className="cursor-pointer sidebar-label">{item.label}</label>
                                            </div>
                                        </NavLink>
                                    )
                                })
                            }
                        </div>) : (children)}

                    </AccordionDetails> */}
                </Accordion >
            </div >
        </div >
    );
}