import React from "react";
import Lottie from 'react-lottie';
import './styles.scss'
import no_data from 'assets/lottie/404.json'

const Page404 = (props) => {

    return (
        <div className="page404-container">
            <Lottie options={
                {
                    loop: true,
                    autoplay: true,
                    animationData: no_data,
                    colSpan: true,
                }
            }
                height={400}
                width={400}
            />
        </div>
    )
};


export default Page404;