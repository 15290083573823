import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import doodletransparent from "../../assets/images/logo_animated.gif";
import instagram from "../../assets/images/instagram.svg";
import facebook from "../../assets/images/facebook.svg";
import twitter from "../../assets/images/twitter.svg";
import youtube from "../../assets/images/youtube.svg";
import linkedin from "../../assets/images/linkedin.svg";
import bluecorners from "../../assets/images/bluecorners.png";
import Topunion from "../../assets/images/Topunion.svg";
import cornerdots from "../../assets/images/cornerdots.svg";
import LoginFooter from "../../component/common/LoginFooter";
import "../../assets/scss/common.scss";
import "./styles.scss";
export function CommonLogin(props) {
  const history = useHistory();
  const [isNotLogin, setNotLogin] = useState(false);

  useEffect(() => {
    setNotLogin(history.location.pathname !== "/auth/login");
  }, [history.location.pathname]);

  return (
    <div className="auth_Layout login-wrapper">
      <div className="doodle_start">
        <div className="total_height">
          <div className="">
            <img className="doodle_logo" src={logo} alt="logo" />
          </div>
          {/* <div className="Topunion">
          <img src={Topunion}></img>
        </div> */}

          <div className="doodle_chart">
            <div
              className={
                isNotLogin ? `doodleblue_text rounded mx-auto d-block` : "w-100"
              }
            >
              {isNotLogin && (
                <img
                  src={doodletransparent}
                  className="doodletext_img"
                  alt="Avatar"
                />
              )}
              {props.children}

              <div></div>
            </div>
          </div>
          {/* <div className="Media">
          <div className="Social_media">
            <p>Connect with us on</p>
            <div className="social_icons">
              <a href="https://www.instagram.com/"><img src={instagram} alt="" /></a>
              <a href="https://www.facebook.com/"><img src={facebook} alt="" /></a>
              <a href="https://twitter.com/login"><img src={twitter} alt="" /></a>
              <a href="https://www.youtube.com/"><img src={youtube} alt="" /></a>
              <a href="https://www.linkedin.com/login"><img src={linkedin} alt="" /></a>
            </div>
               
          </div>
          <div className="Social_connect mt-2"><a href="">Click here</a> <label>to join the doodleblue Community </label></div>
          
        </div> */}
          <LoginFooter />
        </div>

        {/* 
      <p>Login</p>

      <button onClick={()=> history.push('/admin/dashboard')}>Click</button> */}
      </div>
    </div>
  );
}
