import React from "react";
import instagram from "../../../assets/images/instagram.svg";
import facebook from "../../../assets/images/facebook.svg";
import twitter from "../../../assets/images/twitter.svg";
import youtube from "../../../assets/images/youtube.svg";
import linkedin from "../../../assets/images/linkedin.svg";
import "./style.scss";

function LoginFooter() {
  return (
    <div>
      <div className="Media">
        <div className="Social_media">
          <p className="text-center connect_us font-regular-20 mb-0 mb-1 ms-2">
            Connect with us on
          </p>
          <div className="social_icons text-center mt-1">
            <a href="https://www.instagram.com/doodleblue/?hl=en" target="_blank">
              <img src={instagram} alt="" />
            </a>
            <a href="https://www.facebook.com/doodleblue.Innovations/" target="_blank">
              <img src={facebook} alt="" />
            </a>
            <a href="https://twitter.com/doodleblue7?lang=en" target="_blank">
              <img src={twitter} alt="" />
            </a>
            <a href="https://www.youtube.com/c/doodleblue" target="_blank">
              <img src={youtube} alt="" />
            </a>
            <a href="https://www.linkedin.com/company/doodleblue/?originalSubdomain=in" target="_blank">
              <img src={linkedin} alt="" />
            </a>
          </div>
        </div>
        <div className="Social_connect mt-2 text-center ms-2">
          <a href="https://www.doodleblue.com/" target="_blank" className="click_text">
            Click here
          </a>{" "}
          <label className="connect_us">
            to Join the doodleblue Community{" "}
          </label>
        </div>
      </div>
    </div>
  );
}

export default LoginFooter;
