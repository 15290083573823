import NotFound from "pages/NotFound";

const routers = [
  {
    path: "/",
    redirect: "/auth",
  },
  {
    component: "CommonLogin",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth/login",
    childrens: [
      {
        component: "Login",
        path: "/login",
        componentPath: "pages/Login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "ForgotPassword",
        path: "/forgot-password",
        componentPath: "pages/ForgotPassword",
        name: "ForgotPassword",
        auth: false,
        exact: true,
      },

      {
        component: "VerifyOtp",
        path: "/verify-otp",
        componentPath: "pages/VerifyOtp",
        name: "VerifyOtp",
        auth: false,
        exact: true,
      },
      {
        component: "ChangePassword",
        path: "/change-password",
        componentPath: "pages/ChangePassword",
        name: "ChangePassword",
        auth: false,
        exact: true,
      },

    ],
  },

  {
    component: "MainLayout",
    path: "/admin",
    auth: false,
    name: "Dashboard",
    exact: false,
    // redirect: "/admin/dashboard",
    childrens: [
      // DASHBOARD
      {
        component: "AllDashboards",
        path: "/Dashboard",
        componentPath: "pages/AllDashboards",
        name: "AllDashboards",
        auth: false,
        exact: true,
      },
      {
        component: "Dashboard",
        path: "/resource-dashboard",
        componentPath: "pages/Dashboard",
        name: "Dashboard",
        auth: false,
        exact: true,
      },
      {
        component: "Dashboard",
        path: "/qadevops-dashboard",
        componentPath: "pages/QaDevopsDashboard",
        name: "QaDevopsDashboardPage",
        auth: false,
        exact: true,
      },
      {
        component: "AdminDashboard",
        path: "/admin-dashboard",
        componentPath: "pages/AdminDashboard",
        name: "AdminDashboardPage",
        auth: false,
        exact: true,
      },
      //PROFILE
      {
        component: "Profile",
        path: "/profile",
        componentPath: "pages/Profile",
        name: "Profile",
        auth: false,
        exact: true,
      },
      // ALLOCATION
      {
        component: "Allocation",
        path: "/allocation",
        componentPath: "pages/Allocation",
        name: "Allocation",
        auth: false,
        exact: true,
      },
      //PROJECTS
      {
        component: "Projects",
        path: "/project",
        componentPath: "pages/Projects",
        name: "Projects",
        auth: false,
        exact: true,
      },
      {
        component: "ViewProject",
        path: "/project/viewproject",
        componentPath: "pages/Projects/DeliveryUnit/ViewProject",
        name: "ViewComponent",
        auth: false,
        exact: true,
      },
      {
        component: "ViewCuResource",
        path: "/project/view-cuResource",
        componentPath: "pages/Projects/ConsultingUnit/ViewCuResource",
        name: "ViewCuResource",
        auth: false,
        exact: true,
      },
      //
      {
        component: "TaskCompletionView",
        path: "/taskcompletionview",
        componentPath: "pages/TaskCompletionView",
        name: "TaskCompletionView",
        auth: false,
        exact: true,
      },
      {
        component: "OtherEod",
        path: "/othereod",
        componentPath: "pages/OtherEod",
        name: "OtherEod",
        auth: false,
        exact: true,
      },
      //REFER YOUR FRIEND

      {
        component: "ReferYourFriend",
        path: "/referfriend",
        componentPath: "pages/ReferYourFriend",
        name: "ReferFriend",
        auth: false,
        exact: true,
      },
      // Leave management
      {
        component: "LeaveManagement",
        path: "/leavemanagement",
        componentPath: "pages/LeaveManagement",
        name: "LeaveManagement",
        auth: false,
        exact: true,
      },
      //HOLIDAYS
      {
        component: "Holidays",
        path: "/holidays",
        componentPath: "pages/Holidays",
        name: "HolidaysList",
        auth: false,
        exact: true,
      },
      // Manage Employee
      {
        component: "ManageEmployee",
        path: "/manageemployee",
        componentPath: "pages/ManageEmployee",
        name: "ManageEmployee",
        auth: false,
        exact: true,
      },
      {
        component: "ManageTeam",
        path: "/manageteam",
        componentPath: "pages/ManageTeam",
        name: "ManageTeam",
        auth: false,
        exact: true,
      },
      {
        component: "LeaveBalance",
        path: "/leavebalance",
        componentPath: "pages/LeaveBalance",
        name: "LeaveBalance",
        auth: false,
        exact: true,
      },
      //Leave Approvals
      {
        component: "LeaveApprovals",
        path: "/leaveapprovals",
        componentPath: "pages/LeaveApprovals",
        name: "LeaveApprovals",
        auth: false,
        exact: true,
      },
      //Assets Management
      {
        component: "AssetManagement",
        path: "/assetmanagement",
        componentPath: "pages/AssetManagement",
        name: "AssetManagement",
        auth: false,
        exact: true,
      },
      //ADMIN PROJECT
      {
        component: "AdminProject",
        path: "/adminproject",
        componentPath: "pages/AdminProject",
        name: "AdminProjectPage",
        auth: false,
        exact: true,
      },
      {
        component: "AddProject",
        path: "/adminproject/addproject",
        componentPath: "pages/AdminProject/AddProject",
        name: "AddProjectPage",
        auth: false,
        exact: true,
      },
      {
        component: "EditProject",
        path: "/adminproject/editproject",
        componentPath: "pages/AdminProject/EditProject",
        name: "EditProjectPage",
        auth: false,
        exact: true,
      },
      //PMDASHBOARD
      {
        component: "PmDashboard",
        path: "/pmDashboard",
        componentPath: "pages/PmDashboard",
        name: "PmDashboard",
        auth: false,
        exact: true,
      },
      {
        component: "EodReports",
        path: "/eodreport",
        componentPath: "pages/EodReports",
        name: "EodReports",
        auth: false,
        exact: true,
      },
      // REFERRAL APPROVAL
      {
        component: "ReferralApproval",
        path: "/referral_approval",
        componentPath: "pages/ReferralApproval",
        name: "ReferralApprovalPage",
        auth: false,
        exact: true,
      },
      // PM ALLOCATION
      {
        component: "PmAllocation",
        path: "/pm-allocation",
        componentPath: "pages/PmAllocation",
        name: "AllocationPage",
        auth: false,
        exact: true,
      },
      // COMPOFF APPROVAL
      {
        component: "CompOffApproval",
        path: "/compoff-approval",
        componentPath: "pages/CompOffApproval",
        name: "CompOffPage",
        auth: false,
        exact: true,
      },
      // PM PROJECTS
      {
        component: "PmProjects",
        path: "/pm-projects",
        componentPath: "pages/PmProjects",
        name: "PmProjectPage",
        auth: false,
        exact: true,
      },
      {
        component: "Sprint",
        path: "/pm-projects/sprint",
        componentPath: "pages/PmProjects/Sprint",
        name: "SprintPage",
        auth: false,
        exact: true,
      },
      // PM APPROVED TASKS
      {
        component: "PmApprovedTasks",
        path: "/pmapproved-tasks",
        componentPath: "pages/PmApprovedTasks",
        name: "PmApprovalPage",
        auth: false,
        exact: true,
      },
      {
        component: "ApprovalSprint",
        path: "/pmapproved-tasks/approved-sprint",
        componentPath: "pages/PmApprovedTasks/ApprovalSprint",
        name: "ApprovalSprintPage",
        auth: false,
        exact: true,
      },
      {
        component: "ApprovalViewTask",
        path: "/pmapproved-tasks/approved-sprint/viewTasks",
        componentPath: "pages/PmApprovedTasks/ApprovalSprint/ApprovalViewTask",
        name: "ViewTaskPage",
        auth: false,
        exact: true,
      },
      //PM REPORTS 
      {
        component: "PmReports",
        path: "/pm-reports",
        componentPath: "pages/PmReports",
        name: "PmReportsPage",
        auth: false,
        exact: true,
      },
      {
        component: "ProjectSplit",
        path: "/pm-reports/projectteam-split",
        componentPath: "pages/PmReports/ProjectSplit",
        name: "ProjectSplitPage",
        auth: false,
        exact: true,
      },
      // QA 
      {
        component: "TeamEod",
        path: "/team-eod",
        componentPath: "pages/TeamEod",
        name: "TeamEodPage",
        auth: false,
        exact: true,
      },
      {
        component: "DailyEodQa",
        path: "/daily-eod-qa",
        componentPath: "pages/DailyEodQa",
        name: "DailyEodPage",
        auth: false,
        exact: true,
      },
      {
        component: "QaDailyEodReport",
        path: "/qadaily-eodreport",
        componentPath: "pages/QaDailyEodReport",
        name: "QaEodReportPage",
        auth: false,
        exact: true,
      },
      {
        component: "DevOpsEodReport",
        path: "/devops-eodreport",
        componentPath: "pages/DevOpsEodReport",
        name: "DevOpsReportPage",
        auth: false,
        exact: true,
      },
      {
        component: "PMLeaveApprovals",
        path: "/pmleaveapprovals",
        componentPath: "pages/PmScreenLeaveApproval",
        name: "PMLeaveApprovals",
        auth: false,
        exact: true,
      },
      {
        component: "DailyEod",
        path: "/dailyeod",
        componentPath: "pages/DevopsDailyEOD",
        name: "DailyEod",
        auth: false,
        exact: true,
      },
      {
        component: "Telegram Group",
        path: "/telegramgroup",
        componentPath: "pages/AdminProject/TelegramGroup",
        name: "Telegram Group",
        auth: false,
        exact: true,
      },
      // Announcement
      {
        component: "Announcement",
        path: "/announcement",
        componentPath: "pages/Announcement",
        name: "AnnouncementPage",
        auth: false,
        exact: true,
      },
      // BURNT REPORT
      {
        component: "BurntReport",
        path: "/burnt-report",
        componentPath: "pages/BurntReport",
        name: "BurntReportPage",
        auth: false,
        exact: true,
      },
      //Admin Other EOD
      {
        component: "AdminOtherEOD",
        path: "/admin-othereod",
        componentPath: "pages/AdminOtherEOD",
        name: "AdminOtherEodPage",
        auth: false,
        exact: true,
      },
      //Allocation Request
      {
        component: "PmAllocationRequest",
        path: "/allocation-request",
        componentPath: "pages/PmAllocationRequest",
        name: "AllocationRequest",
        auth: false,
        exact: true,
      },
      // Admin Allocation Request
      {
        component: "AdminAllocationRequest",
        path: "/requested-allocation",
        componentPath: "pages/AdminAllocationRequest",
        name: "AdminAllocationRequest",
        auth: false,
        exact: true,
      },
      {
        component: "ExitEmployee",
        path: "/exitemployee",
        componentPath: "pages/ExitEmployee",
        name: "ExitEmployee",
        auth: false,
        exact: true,
      },
      // Tech Arch 
      {
        component: "TechArchTaskUpdate",
        path: "/taskUpdate",
        componentPath: "pages/TechArchTaskUpdate",
        name: "TechArchTaskUpdate",
        auth: false,
        exact: true,
      },
      {
        component: "TechArchHead",
        path: "/techHead",
        componentPath: "pages/TechArchHead",
        name: "TechArchHead",
        auth: false,
        exact: true,
      },
      // CU Allocation Admin
      {
        component: "CuAllocationAdmin",
        path: "/cuallocation-admin",
        componentPath: "pages/CuAllocationAdmin",
        name: "CuAllocationAdmin",
        auth: false,
        exact: true,
      },
      {
        component: "CuAllocationAdmin",
        path: "/cuallocation-admin/view-allocation",
        componentPath: "pages/CuAllocationAdmin/ViewAllocation",
        name: "ViewAllocation",
        auth: false,
        exact: true,
      },
      // CU Allocation PM
      {
        component: "PmCuAllocation",
        path: "/pm-cu-allocation",
        componentPath: "pages/PmCuAllocation",
        name: "PmCuAllocation",
        auth: false,
        exact: true,
      },
      {
        component: "PmCuAllocation",
        path: "/pm-cu-allocation/pmview-allocation",
        componentPath: "pages/PmCuAllocation/PmViewAllocation",
        name: "PmViewAllocation",
        auth: false,
        exact: true,
      },
      {
        component: "PmCuAllocation",
        path: "/pm-cu-allocation/manage-allocation",
        componentPath: "pages/PmCuAllocation/PmManageAllocation",
        name: "PmManageAllocation",
        auth: false,
        exact: true,
      },
      //Archived Project
      {
        component: "ArchivedProjects",
        path: "/archived-projects",
        componentPath: "pages/ArchivedProjects",
        name: "ArchivedProjects",
        auth: false,
        exact: true,
      },
      {
        component: "PmTaskUpdate",
        path: "/pmTaskUpdate",
        componentPath: "pages/PmTaskUpdate",
        name: "TaskUpdate",
        auth: false,
        exact: true,
      },
      //Common Dashboard
      {
        component: "CommonDashboard",
        path: "/hr-dashboard",
        componentPath: "pages/CommonDashboard",
        name: "CommonDashboard",
        auth: false,
        exact: true,
      },
      // Admin Comp Off
      {
        component: "AdminCompOffApproval",
        path: "/admin-compOff",
        componentPath: "pages/AdminCompOffApproval",
        name: "AdminCompOff",
        auth: false,
        exact: true,
      },

      //Admin PM Report
      {
        component: "AdminPmReport",
        path: "/pm-report",
        componentPath: "pages/AdminPmReport",
        name: "AdminPMReport",
        auth: false,
        exact: true,
      },

      //Admin Tech Arch Report 
      {
        component: "AdminTechArchreport",
        path: "/techArch-reports",
        componentPath: "pages/AdminTechArchreport",
        name: "AdminTechArchReport",
        auth: false,
        exact: true,
      },
    ],
  }
];
export default routers;
