import React, { useContext, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { endpoints } from "config/api";
import { AxiosResponse, AxiosError } from "axios";
import { Toast } from "./toast";
import Modal from "component/modal";
import { AES_SECRET_KEY } from '../constants'
import { decryptData, encryptData } from './helpers'

export const request = ({ url, method, data, params, isLoader = true, responseType, headers = {} }) =>
  new Promise((resolve, reject) => {
    let token = localStorage.getItem("TOKEN");
    let config = {
      url: "https://api.doodle360.in/api/v1" + url,
      method: method,
      data: data ? data : null,
      params: params ? params : null,
      headers: {
        'token': token ? token : null,
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
      responseType: responseType ? responseType : undefined
    };
    if (data == null) {
      delete config["data"]
    }


    showLoader(isLoader);

    axios(config)
      .then((res) => {
        showLoader(false);
        if (res.data.body) {
          const decryptedData = decryptData(res.data.body, AES_SECRET_KEY);
          let resp = {
            data: {
              ...decryptedData
            }
          }
          return resolve(resp);
        } else {
          return resolve(res);
        }
      })
      .catch(({ response }) => {
        showLoader(false);
        if (response) {
          let { status, data } = response;
          let { message } = data;
          Toast({ type: "error", message: message });

          if (status === 401) {
            window.location.replace("/auth/login")
            // let history = useHistory()
            //clear and navigate to login
            // history.replace('/auth/login');
          }
        } else {
          Toast({
            type: "error",
            message: "Not able to connect the server. Please try again later",
          });
        }

        return reject(response);
      });
  });

const showLoader = (status) => {
  if (Modal && Modal.render && Modal.render.defaultProps) {
    Modal.render.defaultProps.setLoaderStatus(status);
  }
};
