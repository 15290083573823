import React from "react";
import Sidebar from "../component/common/Sidebar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { mainLayoutStyle } from "service/helpers/Constants";
import Header from "../component/common/Header";
import NotificationDrawer from "component/common/Notification";
import menu_open from "../assets/icons/menu_open.png";
import menu_close from "../assets/icons/menu_close.png";
export function MainLayout(props) {
  const outerTheme = createTheme({
    palette: {
      primary: {
        main: "#1A4BA1",
      },

      secondary: {
        main: "#FBFBFB",
      },
    },
  });
  const classes = mainLayoutStyle();
  return (
    <>

      <ThemeProvider theme={outerTheme}>

        <div className={classes.root + " mb-5"}>
          <Sidebar
            classes={classes} />

          <Header />

          <main className={classes.content}>{props.children}</main>
        </div>
      </ThemeProvider>
    </>
  );
}
