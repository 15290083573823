import { createStore, applyMiddleware } from "redux";
import { reducers } from "../../reducer";
import thunk from "redux-thunk";
// import { api } from "service/api";
import logger from "redux-logger";
import { Toast } from "service/toast";
import CryptoJS from 'crypto-js';

export const store = createStore(
  reducers,
  applyMiddleware(thunk.withExtraArgument({ Toast, logger }))
);

export const history = require("history").createBrowserHistory();

// ENCRYPTION FUNCTION
export const encryptData = (text, key) => {
  if (text && key) return CryptoJS.AES.encrypt(JSON.stringify(text), key).toString()
}

// DECRYPTION FUNCTION
export const decryptData = (text, key) => {
  if (text && key) {
    const bytes = CryptoJS.AES.decrypt(text, key)
    try {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    } catch (err) {
      return null
    }
  }
}