// import { HomeActionType } from "service/actionType"

const initialState = {
   label:""
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case "BUTTON_LABEL":
        
        return {
          ...state,
          label: payload,
        };
      default:
        return state;
    }
  };
  