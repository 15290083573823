import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import notificationIcon from "../../../assets/icons/header/notificationIcon.svg"
import './style.scss';
import { request } from 'service';
import { endpoints } from 'config/api';
import endponts from 'service/endponts';
import moment from 'moment';
import NoData from 'component/NoData';

const NotificationDrawer = () => {
    const [openNotification, setOpenNotification] = useState(false);
    const [notificationData, setNotificationData] = useState([])


    const toggleDrawer = (open) => (event) => {
        setOpenNotification(open);
    };

    const notificationsForEmployess = () => {
        request({
            url: endponts.Endpoints.getNotifications,
            method: endponts.APIMethods.GET
        }).then((response) => {
            let obj = response.data.data.results
            setNotificationData(obj)
        })
    }

    const clearNotificationsForEmployees = () => {
        request({
            url: endponts.Endpoints.clearNotifications,
            method: endponts.APIMethods.DELETE,
        }).then(
            toggleDrawer(false)
        )
    }

    useEffect(() => {
        notificationsForEmployess()
    }, [])
    const list = () => (
        <Box
            sx={{ width: 350 }}
        // role="presentation"
        // onClick={toggleDrawer(false)}
        // onKeyDown={toggleDrawer(false)}
        >
            <List>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='fw-500 fs-20 p-3'>Notifications</div>
                    {
                        notificationData.length > 0 && <div><button className='but blue-text cursor-pointer' onClick={() => clearNotificationsForEmployees()}> Clear All</button></div>
                    }

                </div>
                {notificationData?.length > 0 ?
                    notificationData.map((x, index) => (
                        <div key={index + "_123"} >

                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                                    </ListItemIcon>
                                    <ListItemText className='fs-16' primary={x?.notifyMessage} />
                                    <p className='text-muted fs-12'>{moment(x?.createdAt).fromNow()}</p>
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                        </div>

                    )) : <NoData />}
            </List>
        </Box>
    );

    return (
        <div className='mx-2'>
            <React.Fragment>
                {/* <Button onClick={toggleDrawer(true)}>{'right'}</Button> */}
                <Button onClick={toggleDrawer(true)}>
                    <span className="cursor-pointer"><img width='25px' height='25px' src={notificationIcon} /></span>
                </Button>
                <Drawer
                    anchor={'right'}
                    open={openNotification}
                    onClose={toggleDrawer(false)}
                >
                    {list()}
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default NotificationDrawer;