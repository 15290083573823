import React, { useState, useEffect, useCallback } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
import leavebalance from "../../../assets/icons/sidebar/leavebalance.svg";
import taskCompletionIcon from "../../../assets/icons/sidebar/check-square.svg";
import leaveapprovals from "../../../assets/icons/sidebar/leaveapprovals.svg";
import eodReportIcon from "../../../assets/icons/sidebar/clipboard.svg";
import otherEodIcon from "../../../assets/icons/sidebar/columns.svg";
import manageemployee from "../../../assets/icons/sidebar/manageEmployee.svg";
import leaveManagementIcon from "../../../assets/icons/sidebar/file-plus.svg";
import manageteamIcon from "../../../assets/icons/sidebar/manageteam.svg";
import dashboardIcon from "../../../assets/icons/sidebar/icon-dashboard.svg";
import projectsIcon from "../../../assets/icons/sidebar/layers.svg";
import project from "../../../assets/icons/sidebar/project.svg"
import referYourFriendIcon from "../../../assets/icons/sidebar/users.svg";
import doodleIcon from "../../../assets/icons/sidebar/doodle.png";
import menu_close from "../../../assets/icons/menu_close.png";
import doodleBlueIcon from "../../../assets/icons/sidebar/logo_animated.gif";
import holidayIcon from "../../../assets/icons/sidebar/holiday.svg";
import userCircleIcon from "../../../assets/icons/sidebar/UserCircle.svg";
import clipboard from "../../../assets/icons/Pagination/clipboard.svg";
import allocation from "../../../assets/icons/sidebar/allocation.svg";
import reports from "../../../assets/icons/sidebar/reports.svg";
import leavemanagement from "../../../assets/icons/sidebar/leavemanagement.svg"
import team from "../../../assets/icons/sidebar/team.svg";
import announcement from "../../../assets/icons/sidebar/announcement.svg"
import teleIcon from "../../../assets/icons/sidebar/teleIcon.svg";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./style.scss";
import SideBarAccordion from "../SideBarAccordion";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const location = useLocation();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [childIndex, setChildIndex] = useState(-1);
  const [screens, setScreens] = useState([])
  const [screenCheck, setscreenCheck] = useState([])

  const getResourceType = () => {
    let data = localStorage.getItem("userData");
    let parsedData = JSON.parse(data);
    let screenId = parsedData?.screens?.map((x) => x?.id)
    //Filtering array using screen permission
    let filteredArray = navLink?.filter((x) => screenId?.includes(x?.id))
    let array = [{
      to: "/admin/Dashboard",
      label: "Dashboard",
      icon: dashboardIcon,
      id: 1
    },
    {
      to: "/admin/profile",
      label: "My Profile",
      icon: userCircleIcon,
      id: 2,
    },]

    let endPositions = [


      {
        to: "/admin/referfriend",
        label: "Refer your friend",
        icon: referYourFriendIcon,
        id: 7
      },
      {
        to: "/admin/leavemanagement",
        label: "Leave Management",
        icon: leaveManagementIcon,
        id: 8
      }]


    //Filtered array with group name
    let groupNameArray = filteredArray.filter((x) => ![x?.groupName].includes())

    //Filtered array without group name
    let NonGroupNameArray = filteredArray.filter((x) => [x?.groupName].includes())

    //Filtering dropdowns based on the Group
    let map = new Map();
    for (let index = 0; index < groupNameArray.length; index++) {
      const element = groupNameArray[index];
      if (map.has(element.groupName)) {
        let array = [...map.get(element.groupName)];
        array.push(element);
        map.set(element.groupName, array);
      } else {
        let array = [];
        array.push(element);
        map.set(element.groupName, array);
      }
    }

    //Filtering array based on the group name
    let groupArray = []
    map.forEach((values, keys) => {
      let object = {
        to: "",
        label: keys,
        icon: values[0].icon,
        children: [...values]
      }
      groupArray.push(object)

    })

    //Setting all the filtered arrays to one array
    let finalArray = [...array, ...NonGroupNameArray, ...groupArray, ...endPositions]
    // setscreenCheck([...finalArray])
    return finalArray
  }



  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("userData"))
    setScreens(data.screens)
    getResourceType()
    setscreenCheck([...getResourceType()])
  }, []);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const navLink = [
    //Admin Navigation Links
    // {
    //   to: "/admin/admin-dashboard",
    //   label: "Dashboard",
    //   icon: dashboardIcon,
    //   id: 16,
    // },

    {
      to: "/admin/manageteam",
      label: "Manage Team",
      icon: manageteamIcon,
      id: 17,
    },
    {
      to: "/admin/manageemployee",
      label: "Manage Employees",
      icon: manageemployee,
      id: 18,
    },
    {
      to: "/admin/exitemployee",
      label: "Exit Employees",
      icon: manageemployee,
      id: 19,
    },
    {
      to: "/admin/adminproject",
      label: "Projects",
      icon: projectsIcon,
      id: 20,
    },
    {
      to: "/admin/archived-projects",
      label: "Archived Projects",
      icon: projectsIcon,
      id: 21,
    },
    {
      to: "/admin/allocation",
      label: "Allocation",
      icon: clipboard,
      id: 22,
    },
    {
      to: "/admin/cuallocation-admin",
      label: "CU Report",
      icon: allocation,
      id: 23,
    },
    {
      to: "/admin/holidays",
      label: "Holidays",
      icon: holidayIcon,
      id: 24,
    },
    {
      to: "/admin/leaveapprovals",
      label: "Employee Leave Approval",
      icon: leaveapprovals,
      id: 25,
    },
    {
      to: "/admin/leavebalance",
      label: `Employee Leave Balance`,
      icon: leavebalance,
      id: 26,
    },
    {
      to: "/admin/techArch-reports",
      label: "Tech Arch's EOD",
      id: 41,
      icon: reports,
      groupName: "Reports"
    },
    {
      to: "/admin/pm-report",
      label: "PM's EOD",
      id: 40,
      icon: reports,
      groupName: "Reports"
    },
    {
      to: "/admin/qadaily-eodreport",
      label: "QA's EOD",
      id: 27,
      icon: reports,
      groupName: "Reports"
    },
    {
      to: "/admin/devops-eodreport",
      label: "Devops EOD",
      id: 28,
      icon: reports,
      groupName: "Reports"
    },
    {
      to: "/admin/admin-othereod",
      label: "Other EOD",
      id: 29,
      icon: reports,
      groupName: "Reports"
    },
    // {
    //   to: "/admin/telegramgroup",
    //   label: "Telegram Group",
    //   icon: teleIcon,
    //   id: 30,
    // },

    {
      to: "/admin/burnt-report",
      label: "Burnt Report",
      icon: allocation,
      id: 32,
    },
    {
      to: "/admin/admin-compOff",
      label: "CompOff Approval",
      icon: leavemanagement,
      id: 39,
    },

    //PM Navigation Links

    // {
    //   to: "/admin/pmDashboard",
    //   label: "Dashboard",
    //   icon: dashboardIcon,
    //   id: 9,
    // },

    {
      to: "/admin/pmTaskUpdate",
      label: "Task Update",
      icon: team,
      id: 10,
    },

    {
      to: "/admin/pm-projects",
      label: "Projects",
      id: 11,
      groupName: "DU Projects",
      icon: project,
    },
    {
      to: "/admin/pmapproved-tasks",
      label: "Approve Tasks",
      id: 12,
      groupName: "DU Projects",
      icon: project,
    },



    // {
    //   to: "/admin/pm-allocation",
    //   label: "Allocation",
    //   icon: allocation,
    // },
    // {
    //   to: "/admin/allocation-request",
    //   label: "Allocation Request",
    //   icon: allocation,
    // },
    {
      to: "/admin/pm-cu-allocation",
      label: "CU Projects",
      icon: allocation,
      id: 13,
    },
    {
      to: "/admin/pmleaveapprovals",
      label: "Leave Approval",
      id: 14,
      icon: leavemanagement,
      groupName: "Approvals"

    },
    {
      to: "/admin/compoff-approval",
      label: "Comp Off Approval",
      id: 15,
      icon: leavemanagement,
      groupName: "Approvals"

    },


    //HR Navigation Link
    // {
    //   to: "/admin/hr-dashboard",
    //   label: "Dashboard",
    //   icon: dashboardIcon,
    // },

    // {
    //   to: "/admin/pmleaveapprovals",
    //   label: "Leave Approval",
    //   groupName: "Approvals",
    //   icon: leaveapprovals,
    //   id: 14,
    // },

    {
      to: "/admin/referral_approval",
      label: "Referral Approval",
      // groupName: "Approval",
      icon: leaveapprovals,
      id: 38
    },

    //Tech Arc navigation links

    // {
    //   to: "/admin/qadevops-dashboard",
    //   label: "Dashboard",
    //   icon: dashboardIcon,
    // },
    {
      to: "/admin/taskUpdate",
      label: "Task Update",
      icon: team,
      id: 36
    },
    {
      to: "/admin/techHead",
      label: "Team EOD",
      icon: team,
      id: 37
    },




    //Devops Navigation Link
    // {
    //   to: "/admin/qadevops-dashboard",
    //   label: "Dashboard",
    //   icon: dashboardIcon,
    // },
    {
      to: "/admin/dailyeod",
      label: "Daily EOD",
      icon: team,
      id: 35
    },

    //QA Navigation Link
    // {
    //   to: "/admin/qadevops-dashboard",
    //   label: "Dashboard",
    //   icon: dashboardIcon,
    // },
    {
      to: "/admin/daily-eod-qa",
      label: "Daily EOD",
      icon: team,
      id: 33
    },
    {
      to: "/admin/team-eod",
      label: "Team EOD",
      icon: team,
      id: 34
    },

    //Resource Navigation Link

    // {
    //   to: "/admin/dashboard",
    //   label: "Dashboard",
    //   icon: dashboardIcon,
    //   id: 1
    // },
    {
      to: "/admin/project",
      label: "Projects",
      icon: projectsIcon,
      id: 3
    },
    {
      to: "/admin/taskcompletionview",
      label: "Approved Tasks",
      icon: taskCompletionIcon,
      id: 4
    },
    {
      to: "/admin/eodreport",
      label: "EOD Report",
      icon: eodReportIcon,
      id: 6
    },

    //Common 
    {
      to: "/admin/announcement",
      label: "Announcement",
      icon: announcement,
      id: 31,
    },

    {
      to: "/admin/othereod",
      label: "Other EOD",
      icon: otherEodIcon,
      id: 5
    },



  ]
  // //Setting Screens Permission

  // const screenCheckFunction = useCallback(() => {
  //   const filteredArray = screenCheck?.filter(obj1 =>
  //     screens.some(obj2 => obj1.id === obj2.id)
  //   );
  // }, [screens, screenCheck])

  // useEffect(() => {
  //   screenCheckFunction()
  // }, [screenCheckFunction])

  return (
    <Box>
      <Drawer anchor="left" variant="permanent" open={open} className="scrollBar">
        <DrawerHeader>
          <div className="w-100">
            {open ? (
              <>
                <img className="px-2" src={doodleBlueIcon} width={170} height={40} alt="logo" />
              </>
            ) : (
              <>
                <img className="doodle-icon cursor-pointer" src={doodleIcon} alt="toggle Drawer" />

                <img onClick={toggleDrawer} className="doodle-icon cursor-pointer" src={doodleIcon} alt="toggle Drawer" />
              </>
            )}
            {open &&
              <>
                <img onClick={toggleDrawer}
                  className={"menu_open cursor-pointer  "} src={menu_close} alt="close" />
              </>
            }
          </div>
          {/* <IconButton
            className={open ? "toggle-drawer" : "toggle-drawer-minimized"}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton> */}
        </DrawerHeader>
        <Divider />

        <List className="navData">
          {getResourceType()?.map(({ label, icon, to, children }, index, toggleDrawer) => (
            <div key={`${index}_1`}>
              {children && children.length > 0 ?
                <SideBarAccordion
                  onClick={(i) => {
                    setActiveIndex(index);
                    setChildIndex(i)
                  }}
                  index={index}
                  activeIndex={activeIndex}
                  parentIndex={index}
                  childIndex={childIndex}
                  label={label}
                  icon={icon}
                  isOpen={open}
                  onClickCarausel={() => {
                    setOpen(true)
                  }}
                  drawer={toggleDrawer}
                  data={children} /> :
                <>

                  <NavLink
                    key={index}
                    to={to}
                    onClick={() => setActiveIndex(-1)}
                    style={{ textDecoration: "none" }}>

                    <ListItem
                      disablePadding
                      sx={{ display: "block" }}
                      className={
                        location.pathname === to ? "active-div" : "inActive-div"
                      }
                    >
                      <ListItemButton
                        sx={{
                          minWidth: 0,
                          mr: open ? 2 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            className={
                              location.pathname === to
                                ? "sidebar-icon"
                                : "sidebar-icon-dimmed"
                            }
                            alt="icon"
                            src={icon}
                          />
                        </ListItemIcon>
                        <ListItemText
                          className="side-bar-text"
                          primary={label}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </NavLink>

                </>
              }
            </div>

          ))}
        </List>
      </Drawer>
    </Box>
  );
}
