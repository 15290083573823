import React from "react";

export function AuthLayout(props) {
  return (
    <div className="">
      {props.children}
    </div>
  );
}

// export default AuthLayout;
